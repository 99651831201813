<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 type-line">개인 상담관리 (푸르지오~사업지)
          </h1>
          <!-- 상담이력 목록 -->
          <div class="box-ct">
            <h2 class="tit-h2 d-flex align-center">상담이력 목록
              <div class="ml-auto">
                <v-btn outlined small class="btn-default">신규</v-btn>
                <v-btn outlined small class="btn-etc ml-2">삭제</v-btn>
              </div>
            </h2>
            <v-data-table
              dense
              height="260px"
              fixed-header
              hide-default-footer
              :headers="gridDataHeaders"
              :items="FnRowNum(gridDataText)"
              :items-per-page="30"
              item-key="name"
              :page.sync="page"
              class="grid-default"
              @page-count="pageCount = $event"
            ></v-data-table>
            <div class="grid-paging text-center pt-2">
              <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
          <!-- 상세내용,설문내용 탭 -->
          <v-tabs
            class="tabs-form"
            slider-color="#F1B02A"
            slider-size="3"
          >
            <v-tab>상세내용</v-tab>
            <v-tab>설문내용</v-tab>

            <v-tab-item>
              <!-- 상세내용 컨텐츠 -->
              <div class="table-form mt-3">
                <table>
                  <caption class="hide">
                    사용자정보 항목
                  </caption>
                  <colgroup>
                    <col width="127px" />
                    <col width="" />
                    <col width="127px" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        시간범위
                      </th>
                      <td> </td>
                      <th scope="row">
                        최대상담인원
                      </th>
                      <td> </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        시간단위
                      </th>
                      <td> </td>
                      <th scope="row">
                        상담제외시간
                      </th>
                      <td> </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        시간단위
                      </th>
                      <td> </td>
                      <th scope="row">
                        상담제외시간
                      </th>
                      <td> </td>
                    </tr>
                    <tr>
                      <th scope="row">상담내용</th>
                      <td colspan="3">
                        <v-textarea
                          class="textarea-type0"
                          label=""
                          no-resize
                          outlined
                        ></v-textarea>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </v-tab-item>
            <v-tab-item>
              <!-- 설문내용 컨텐츠 -->
              <div class="survey-qa-list scrollable">
                <ul>
                  <li v-for="(item,index) in SURVEY_LIST" :key="item">
                    <p class="survey-qa-list--q">
                      {{ index + 1 }}.
                      {{ item.Q }}
                    </p>
                    <div class="survey-qa-list--a">
                      <v-radio-group v-model="radioGroupSelected[item.index]" row class="radio-inline">
                        <v-radio
                         v-for="(child) in item.A"
                         :key="child.text"
                         :label="child.text"
                        :on-icon="'svg-rad-on'"
                        :off-icon="'svg-rad-off'"
                        :value="child.text">
                      </v-radio>
                      </v-radio-group>
                    </div>
                  </li>
                </ul>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
        <!-- 프로필 정보 -->
        <div class="ml-7 flex-grow-1">
          <h1 class="tit-h1 pt-7 d-flex align-center type-line"></h1>
          <div class="survey-fix-list">
            <!-- 프로필 플러그인 정보 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title">프로필 플러그인 정보</div>
              <div class="survey-fix-list--form">
                <v-text-field
                  class="form-inp"
                  name="name" outlined  placeholder="닉네임">
                </v-text-field>
                <v-text-field
                  class="form-inp ml-2"
                  name="name" outlined  placeholder="전화번호">
                </v-text-field>
                <v-text-field
                  class="form-inp ml-2"
                  name="name" outlined  placeholder="이메일">
                </v-text-field>
                <v-text-field
                  class="form-inp"
                  name="name" outlined  placeholder="연령대">
                </v-text-field>
                <v-text-field
                  class="form-inp ml-2"
                  name="name" outlined  placeholder="성별">
                </v-text-field>
              </div>
            </div>
            <!-- 개인정보 수집 및 마케팅 활용 여부 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title">개인정보 수집 및 마케팅 활용 여부</div>
              <div class="survey-fix-list--form">
                <v-checkbox
                  :on-icon="'svg-chk-on'"
                  :off-icon="'svg-chk-off'"
                  class="d-inline-flex"
                  label="개인정보 수집동의"></v-checkbox>
                <v-checkbox
                  :on-icon="'svg-chk-on'"
                  :off-icon="'svg-chk-off'"
                  class="d-flex"
                  label="분양정보 제공 및 마케팅 활용 동의"></v-checkbox>
              </div>
            </div>
            <!-- 이름 및 휴대폰 전화번호를 확인해 주세요 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title">이름 및 휴대폰 전화번호를 확인해 주세요</div>
              <div class="survey-fix-list--form">
                <v-text-field
                  class="form-inp"
                  name="name" outlined  placeholder="고객 성명">
                </v-text-field>
                <v-text-field
                  class="form-inp ml-2"
                  name="name" outlined  placeholder="고객 휴대전화번호">
                </v-text-field>
              </div>
            </div>
            <!-- 고객 성향을 선택해주세요 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title">고객 성향을 선택해주세요</div>
              <div class="survey-fix-list--form">
                <v-radio-group v-model="CUS_ATTITUDE_RADIO_SELECT" row class="radio-inline">
                  <v-radio v-for="(item,index) in CUS_ATTITUDE_RADIO" :key="CUS_ATTITUDE_RADIO[index].text" :label="item.text"
                    :on-icon="'svg-rad-on'"
                    :off-icon="'svg-rad-off'"
                    :value="item.text">
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <!-- 고객 코멘트 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title">고객 코멘트</div>
              <div class="survey-fix-list--form">
                <v-textarea
                  class="textarea-type0"
                  placeholder="내용을 입력해주세요"
                  no-resize
                  outlined
                ></v-textarea>
              </div>
            </div>
            <!-- 상담 고객 대기리스트 -->
            <div class="survey-fix-list--field">
              <div class="survey-fix-list--title">상담 고객 대기리스트</div>
              <div class="survey-fix-list--form">
                <div class="box-line">
                  <v-btn outlined small class="btn-etc2">상담알림 보내기</v-btn>
                  <v-btn outlined small class="btn-etc ml-2">미처리</v-btn>
                </div>
              </div>
            </div>
            <div class="survey-fix-list--footer">
              <span class="survey-fix-list--footer-total">총: <strong>{{ SURVEY_TOTAL }}명 대기중</strong></span>
              <v-btn outlined small class="btn-point ml-2">저장</v-btn>
            </div>
          </div>

        </div>
      </div>
    </div>

  </main>
</template>

<script>
  export default {
    name: "MENU_CAM0400", //name은 'MENU_' + 파일명 조합
    components: {

    },
    data() {
      return {
        CUS_ATTITUDE_RADIO_SELECT: 'S',
        CUS_ATTITUDE_RADIO: [
          { text: 'S' },
          { text: 'A' },
          { text: 'B' },
          { text: 'C' },
          { text: 'D' },
          { text: 'E' },
        ],
        SURVEY_TOTAL: '0',
        SURVEY_LIST: [
          {
            Q: "지난 봄, 거창군 첫 더샵으로 인사드렸던  ‘더샵 거창포르시엘 1차’는 거창 역대 최다 청약경쟁률을 기록하는 등 지역 여러분께 많은 사랑을 받았습니다. 연이어 분야을 앞둔 저희 ‘더샵 거창포르시엘 2차’의 장점은 무엇이라고 생각하시나요? (중복선택가능) ",
            A: [
              { text: '대한민국 주거 NO.1 더샵의 브랜드 파워'},
              { text: '더샵 거창포르시엘2차로 완성되는 거창 최대규모 브랜드 타운'},
              { text: '도보권 아림초, 대성중고, 대성일고, 거창중앙고 등 명문학군'},
              { text: '살기 좋은 주거타운의 완성, 가치높은 거창의 새로운 중심'},
            ]
          },
          {
            Q: "지난 봄, 거창군 첫 더샵으로 인사드렸던  ‘더샵 거창포르시엘 1차’는 거창 역대 최다 청약경쟁률을 기록하는 등 지역 여러분께 많은 사랑을 받았습니다. 연이어 분야을 앞둔 저희 ‘더샵 거창포르시엘 2차’의 장점은 무엇이라고 생각하시나요? (중복선택가능) ",
            A: [
              { text: 'a대한민국 주거 NO.1 더샵의 브랜드 파워'},
              { text: 'b더샵 거창포르시엘2차로 완성되는 거창 최대규모 브랜드 타운'},
              { text: 'c도보권 아림초, 대성중고, 대성일고, 거창중앙고 등 명문학군'},
              { text: 'd살기 좋은 주거타운의 완성, 가치높은 거창의 새로운 중심'},
            ]
          },
        ],
        radioGroupSelected: '',
        gridDataHeaders: [
          { text: 'NO', value: 'index', align: 'center',width: '60px'  },
          { text: '캠페인명', value: 'index01', align: 'center'},
          { text: '상담날짜', value: 'index02', align: 'center'},
          { text: '상담사아이디', value: 'index03', align: 'center'},
          { text: '고객등급', value: 'index04', align: 'center'},
        ],

        gridDataText: [

          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
          },
          {
            index01: 'index01',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
          },
          {
            index01: 'index1111111',
            index02: 'index02',
            index03: 'index03',
            index04: 'index04',
          },
        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 10,
      };
    },

    mounted() {

    },

    methods: {
      FnRowNum(data) {
        return data.map(
          (data, index) => ({
          ...data,
          index: index + 1
        }))
      },
    },

    computed: {

    },
  };
</script>